/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: "Peugeot_Light";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/d1/peugeot/PeugeotNew-Light.ttf");
}

@font-face {
  font-family: "Peugeot_Regular";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/d1/peugeot/PeugeotNew-Regular.ttf");
}

@font-face {
  font-family: "Peugeot_Bold";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/d1/peugeot/PeugeotNew-Bold.ttf");
}

@font-face {
  font-family: "PSA_Regular";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/psa/PSAGroupeHMISans-Regular.ttf");
}

@font-face {
  font-family: "Citroen_Regular";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/citroen-new/ZonaPro-Regular.ttf");
}

@font-face {
  font-family: "Citroen_Bold";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/citroen-new/ZonaPro-Bold.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/alfa-romeo/Helvetica/Helvetica.ttf");
}
@font-face {
  font-family: "AlfaRomeo_Regular";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/alfa-romeo/SequelBlack/Sequel100Black-45.ttf");
}

@font-face {
  font-family: "AlfaRomeo_Bold";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/alfa-romeo/SequelBlack/Sequel100Black-45.ttf");
}

@font-face {
  font-family: "DS_title_italic";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/ds/DSTitleWebSmall_Italic.ttf");
}

@font-face {
  font-family: "DS_Bold";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/DS-New/DSAutomobilesPro-Bold.ttf");
}

@font-face {
  font-family: "DS_regular";
  src: url("https://www-rec.lev.awsmpsa.com/assets/fonts/DS-New/DSAutomobilesPro-Regular.ttf")
    format("truetype");
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.bold {
  font-weight: bold;
}

.align_left {
  text-align: left;
}

.align_justify {
  text-align: justify;
}

.align_center {
  text-align: center;
}

.padding_bottom {
  padding-bottom: 2%;
}

.padding_top {
  padding-top: 2%;
}

/* /* @font-face {
    font-family: "Peugeot_Light";
    src: url("assets/fonts/peugeot/Peugeot\ Light\ v2.otf");
  }

  @font-face {
    font-family: "Peugeot_Bold";
    src: url("assets/fonts/peugeot/Peugeot\ Bold\ v2.otf");
  }

.peugeot_light_font {
  font-family: "Peugeot_Light";
}

.peugeot_bold_font {
  font-family: "Peugeot_Bold";
}

.peugeot_background_color{
  background-color: #0062C5 !important;
}

.citroen_background_color{
  background-color: red !important;
}

.ds_background_color{
  background-color: yellow !important;
}

.citroen_font {
  color: rgb(12, 136, 158);
}

.ds_font {
  color: blue;
} */

::ng-deep .si-float-wrapper {
  position: absolute;
  width: 100%;
}

::ng-deep .si-float-wrapper,
.si-float-wrapper * {
  box-sizing: border-box;
}

::ng-deep [class*="si-wrapper"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  cursor: default;
}

::ng-deep .si-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

::ng-deep .si-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

::ng-deep .si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

::ng-deep .si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

::ng-deep [class*="si-shadow-wrapper"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.29804;
  z-index: 1;
}

::ng-deep .si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

::ng-deep .si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

::ng-deep .si-shadow-frame {
  box-shadow: 0 1px 3px 0 #000;
}

::ng-deep [class*="si-shadow-pointer"] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto;
}

::ng-deep [class*="si-shadow-inner-pointer"] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: -0.70711px 0.70711px 3px 0 #000;
}

::ng-deep .si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

::ng-deep .si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
  transform: translate(-50%, 50%) rotate(-45deg);
}

::ng-deep .si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

::ng-deep .si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
  transform: translate(50%, -50%) rotate(-45deg);
}

::ng-deep .si-frame {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
}

::ng-deep .si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 30px;
  background-color: #fff;
}

::ng-deep .si-has-border .si-content-wrapper {
  border: 1px solid #bbb;
}

::ng-deep .si-content {
  overflow: auto;
}

::ng-deep .si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 24px;
  cursor: pointer;
  opacity: 0.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::ng-deep .si-close-button:hover,
.si-close-button:focus {
  opacity: 0.7;
}

::ng-deep [class*="si-pointer-border"] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3;
}

::ng-deep [class*="si-pointer-bg"] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4;
}

::ng-deep .si-has-border [class*="si-pointer-bg"] {
  border-width: 15px;
}

::ng-deep .si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

::ng-deep .si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

::ng-deep .si-pointer-top {
  border-bottom: 0;
}

::ng-deep .si-pointer-border-top {
  bottom: 0;
  border-top-color: #bbb;
}

::ng-deep .si-pointer-bg-top {
  border-top-color: #fff;
}

::ng-deep .si-has-border .si-pointer-bg-top {
  top: -1px;
  margin-bottom: 0px;
}

::ng-deep .si-pointer-bottom {
  border-top: 0;
}

::ng-deep .si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #bbb;
}

::ng-deep .si-pointer-bg-bottom {
  border-bottom-color: #fff;
}

::ng-deep .si-has-border .si-pointer-bg-bottom {
  bottom: -1px;
  margin-top: 0px;
}

::ng-deep .si-pointer-left {
  border-right: 0;
}

::ng-deep .si-pointer-border-left {
  right: 0;
  border-left-color: #bbb;
}

::ng-deep .si-pointer-bg-left {
  border-left-color: #fff;
}

::ng-deep .si-has-border .si-pointer-bg-left {
  left: -1px;
  margin-right: 0px;
}

::ng-deep .si-pointer-right {
  border-left: 0;
}

::ng-deep .si-pointer-border-right {
  left: 0;
  border-right-color: #bbb;
}

::ng-deep .si-pointer-bg-right {
  border-right-color: #fff;
}

::ng-deep .si-has-border .si-pointer-bg-right {
  right: -1px;
  margin-left: 0px;
}
